.theme--light.v-data-table {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table .v-data-table__divider {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table thead tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table thead tr th {
  color: rgba(0, 0, 0, 0.6);
}
.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table tbody tr:not(:last-child) td:last-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table tbody tr.active {
  background: #f5f5f5;
}
.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: #eeeeee;
}

.theme--dark.v-data-table {
  background-color: #1E1E1E;
  color: #FFFFFF;
}
.theme--dark.v-data-table .v-data-table__divider {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}
.theme--dark.v-data-table.v-data-table--fixed-header thead th {
  background: #1E1E1E;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.12);
}
.theme--dark.v-data-table thead tr:last-child th {
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
}
.theme--dark.v-data-table thead tr th {
  color: rgba(255, 255, 255, 0.7);
}
.theme--dark.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
}
.theme--dark.v-data-table tbody tr:not(:last-child) td:last-child {
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
}
.theme--dark.v-data-table tbody tr.active {
  background: #505050;
}
.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: #616161;
}

.v-data-table table {
  width: 100%;
  border-spacing: 0;
}
.v-data-table td, .v-data-table th {
  padding: 0 16px;
}
.v-data-table th {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 0.75rem;
  height: 48px;
}
.v-application--is-ltr .v-data-table th {
  text-align: left;
}
.v-application--is-rtl .v-data-table th {
  text-align: right;
}
.v-data-table td {
  font-size: 0.875rem;
  height: 48px;
}

.v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.v-data-table__progress {
  height: auto !important;
}
.v-data-table__progress th {
  height: auto !important;
  border: none !important;
  padding: 0;
}

.v-data-table--dense td {
  height: 24px;
}
.v-data-table--dense th {
  height: 32px;
}

.v-data-table--fixed-height .v-data-table__wrapper {
  overflow-y: auto;
}

.v-data-table--fixed-header .v-data-table__wrapper {
  overflow-y: auto;
}
.v-data-table--fixed-header thead th {
  border-bottom: 0px !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.v-data-table--fixed-header thead tr:nth-child(2) th {
  top: 48px;
}
.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 17px;
}
.v-application--is-rtl .v-data-table--fixed-header .v-data-footer {
  margin-left: 17px;
}

.v-data-table--fixed.v-data-table--dense thead tr:nth-child(2) th {
  top: 32px;
}