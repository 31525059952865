.v-menu {
  display: none;
}
.v-menu--attached {
  display: inline;
}
.v-menu__content {
  position: absolute;
  display: inline-block;
  border-radius: 4px;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.v-menu__content--active {
  pointer-events: none;
}
.v-menu__content--auto .v-list-item {
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-menu__content--fixed {
  position: fixed;
}
.v-menu__content > .card {
  contain: content;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.v-menu > .v-menu__content {
  max-width: none;
}
.v-menu-transition-enter .v-list-item {
  min-width: 0;
  pointer-events: none;
}
.v-menu-transition-enter-to .v-list-item {
  pointer-events: auto;
  transition-delay: 0.1s;
}
.v-menu-transition-leave-active, .v-menu-transition-leave-to {
  pointer-events: none;
}
.v-menu-transition-enter, .v-menu-transition-leave-to {
  opacity: 0;
}
.v-menu-transition-enter-active, .v-menu-transition-leave-active {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.v-menu-transition-enter.v-menu__content--auto {
  transition: none !important;
}
.v-menu-transition-enter.v-menu__content--auto .v-list-item {
  opacity: 0;
  transform: translateY(-15px);
}
.v-menu-transition-enter.v-menu__content--auto .v-list-item--active {
  opacity: 1;
  transform: none !important;
  pointer-events: auto;
}