.theme--light.v-sheet {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-sheet {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
  color: #FFFFFF;
}

.v-sheet {
  border-radius: 4px;
}
.v-sheet--tile {
  border-radius: 0;
}