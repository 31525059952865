.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:focus:not(:active) {
  border-color: rgba(0, 0, 0, 0.26);
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
  color: #000000;
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #1E1E1E;
  color: #FFFFFF;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:focus:not(:active) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
  color: #FFFFFF;
}

.v-btn-toggle {
  border-radius: 4px;
  display: inline-flex;
  max-width: 100%;
}
.v-btn-toggle > .v-btn.v-btn {
  border-radius: 0;
  border-style: solid;
  border-width: thin;
  box-shadow: none;
  box-shadow: none;
  opacity: 0.8;
  padding: 0 12px;
}
.v-btn-toggle > .v-btn.v-btn:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.v-btn-toggle > .v-btn.v-btn:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: inherit;
  opacity: 1;
}
.v-btn-toggle > .v-btn.v-btn:after {
  display: none;
}
.v-btn-toggle > .v-btn.v-btn:not(:first-child) {
  border-left-width: 0;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 48px;
  min-height: 0;
  min-width: 48px;
}

.v-btn-toggle--borderless > .v-btn.v-btn {
  border-width: 0;
}

.v-btn-toggle--dense > .v-btn.v-btn {
  padding: 0 8px;
}

.v-btn-toggle--group {
  border-radius: 0;
}
.v-btn-toggle--group > .v-btn.v-btn {
  background-color: transparent !important;
  border-color: transparent;
  margin: 4px;
  min-width: auto;
}

.v-btn-toggle--rounded {
  border-radius: 24px;
}

.v-btn-toggle--shaped {
  border-radius: 24px 4px;
}

.v-btn-toggle--tile {
  border-radius: 0;
}