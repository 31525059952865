.theme--light.v-footer {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-footer {
  background-color: #272727;
  color: #FFFFFF;
}

.v-footer {
  align-items: center;
  display: flex;
  flex: 0 1 auto !important;
  flex-wrap: wrap;
  padding: 6px 16px;
  position: relative;
  transition-duration: 0.2s;
  transition-property: background-color, left, right;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.v-footer:not([data-booted=true]) {
  transition: none !important;
}

.v-footer--absolute,
.v-footer--fixed {
  z-index: 3;
}

.v-footer--absolute {
  position: absolute;
  width: 100%;
}

.v-footer--fixed {
  position: fixed;
}

.v-footer--padless {
  padding: 0px;
}