.theme--light.v-calendar-events .v-event-timed {
  border: 1px solid !important;
}
.theme--light.v-calendar-events .v-event-more {
  background-color: #FFFFFF;
}
.theme--light.v-calendar-events .v-event-more.v-outside {
  background-color: #f7f7f7;
}

.theme--dark.v-calendar-events .v-event-timed {
  border: 1px solid !important;
}
.theme--dark.v-calendar-events .v-event-more {
  background-color: #303030;
}
.theme--dark.v-calendar-events .v-event-more.v-outside {
  background-color: #202020;
}

.v-calendar .v-event {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  margin-right: -1px;
  z-index: 1;
  border-radius: 4px;
}
.v-calendar .v-event-more {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  z-index: 1;
  position: relative;
}
.v-calendar .v-event-timed-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: 10px;
  pointer-events: none;
}
.v-calendar .v-event-timed {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  pointer-events: all;
}
.v-calendar.v-calendar-events .v-calendar-weekly__head-weekday {
  margin-right: -1px;
}
.v-calendar.v-calendar-events .v-calendar-weekly__day {
  overflow: visible;
  margin-right: -1px;
}