.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: text;
}
.v-autocomplete input {
  align-self: center;
}
.v-autocomplete--is-selecting-index input {
  opacity: 0;
}
.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input {
  margin-top: 24px;
}
.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input {
  margin-top: 20px;
}
.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  pointer-events: inherit;
}
.v-autocomplete__content.v-menu__content {
  border-radius: 0;
}
.v-autocomplete__content.v-menu__content .v-card {
  border-radius: 0;
}