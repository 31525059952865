.v-slide-group {
  display: flex;
}
.v-slide-group:not(.v-slide-group--has-affixes) .v-slide-group__prev,
.v-slide-group:not(.v-slide-group--has-affixes) .v-slide-group__next {
  display: none;
}
.v-slide-group.v-item-group > .v-slide-group__next,
.v-slide-group.v-item-group > .v-slide-group__prev {
  cursor: pointer;
}

.v-slide-item {
  display: inline-flex;
  flex: 0 1 auto;
}

.v-slide-group__next,
.v-slide-group__prev {
  align-items: center;
  display: flex;
  flex: 0 1 52px;
  justify-content: center;
  min-width: 52px;
}

.v-slide-group__content {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}

.v-slide-group__wrapper {
  contain: content;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.v-slide-group__next--disabled,
.v-slide-group__prev--disabled {
  pointer-events: none;
}