.theme--light.v-badge .v-badge__badge::after {
  border-color: #FFFFFF;
}

.theme--dark.v-badge .v-badge__badge::after {
  border-color: #1E1E1E;
}

.v-badge {
  display: inline-block;
  line-height: 1;
  position: relative;
}
.v-badge__badge {
  border-radius: 10px;
  color: #FFFFFF;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  padding: 4px 6px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
.v-application--is-ltr .v-badge__badge {
  right: auto;
}
.v-application--is-rtl .v-badge__badge {
  left: auto;
}
.v-badge__badge .v-icon {
  color: inherit;
  font-size: 12px;
  margin: 0 -2px;
}
.v-badge__badge .v-img {
  height: 12px;
  width: 12px;
}
.v-badge__wrapper {
  flex: 0 1;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.v-badge--avatar .v-badge__badge {
  padding: 0;
}
.v-badge--avatar .v-badge__badge .v-avatar {
  height: 20px !important;
  min-width: 0 !important;
  max-width: 20px !important;
}
.v-badge--bordered .v-badge__badge::after {
  border-radius: inherit;
  border-width: 2px;
  border-style: solid;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.15);
}
.v-badge--dot .v-badge__badge {
  border-radius: 4.5px;
  height: 9px;
  min-width: 0;
  padding: 0;
  width: 9px;
}
.v-badge--dot .v-badge__badge::after {
  border-width: 1.5px;
}
.v-badge--icon .v-badge__badge {
  padding: 4px 6px;
}
.v-badge--inline {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.v-badge--inline .v-badge__badge,
.v-badge--inline .v-badge__wrapper {
  position: relative;
}
.v-badge--inline .v-badge__wrapper {
  margin: 0 4px;
}
.v-badge--tile .v-badge__badge {
  border-radius: 0;
}