.v-tooltip {
  display: none;
}
.v-tooltip--attached {
  display: inline;
}
.v-tooltip__content {
  background: rgba(97, 97, 97, 0.9);
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  position: absolute;
  text-transform: initial;
  width: auto;
  opacity: 1;
  pointer-events: none;
}
.v-tooltip__content--fixed {
  position: fixed;
}
.v-tooltip__content[class*=-active] {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.v-tooltip__content[class*=enter-active] {
  transition-duration: 150ms;
}
.v-tooltip__content[class*=leave-active] {
  transition-duration: 75ms;
}